<template>
  <div
    class="fancy-hero-six"
    style="background-color: #b0cf00; padding: 95px 0 95px"
  >
    <div class="container">
      <h3
        style="color: white; font-size: 3.75rem; font-weight: 100"
        class="wa-font-3"
      >
        Etwas Inspiration gefällig?
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
