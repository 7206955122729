<template>
  <div class="terms_and_policy" style="border-bottom: none">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#opt1">Impressum</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="tab" href="#opt2">Datenschutzerklärung</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-8">
          <!-- Tab panes -->
          <div class="tab-content">
            <div
              v-for="(item, index) in terms_data"
              :key="index"
              :id="item.id"
              :class="`tab-pane fade ${item.active ? 'active show' : ''}`"
            >
              <h2 class="font-gilroy-bold">{{ item.title }}</h2>
              <div class="update-date">{{ item.date }}</div>

              <div v-if="item.id === 'opt1'">
                <div>{{ item.p1 }}</div>
                <div class="pb-25">{{ item.p2 }}</div>
                <h4 style="font-weight: 600; padding-bottom: 0.75rem">{{ item.comp }}</h4>
                <div>{{ item.add1 }}</div>
                <div>{{ item.add2 }}</div>
                <div class="pb-25">{{ item.add3 }}</div>
                <!-- <div style="font-weight: 600">{{ item.add4 }}</div>
                <div class="pb-25">{{ item.add5 }}</div>
                <div style="font-weight: 600">{{ item.add6 }}</div>
                <div>{{ item.add7 }}</div>
                <div class="pb-25">{{ item.add8 }}</div> -->
                <div style="font-weight: 600">{{ item.add9 }}</div>
                <div>
                  <a :href="item.add10">{{ item.add10 }}</a>
                </div>
                <div>
                  <a :href="item.add11">{{ item.add11 }}</a>
                </div>
              </div>

              <div v-if="item.id === 'opt1'">
                <template v-for="(para, index) in item.para" :key="index">
                  <div>
                    <h3>{{ para.title }}</h3>
                    <p>{{ para.text }}</p>
                  </div>
                </template>
              </div>

              <div v-if="item.id === 'opt2'">
                <p>
                  Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie
                  zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte
                  und der dort angebotenen Leistungen, verarbeitet.
                </p>
                <p>
                  Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“
                  genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede
                  solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das
                  Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch
                  Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
                  das Löschen oder die Vernichtung.
                </p>
                <p>
                  Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und
                  Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die
                  Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu
                  Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten
                  in wiederum eigener Verantwortung verarbeiten.
                </p>
                <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
                <p>
                  I. Informationen über uns als Verantwortliche<br />II. Rechte der Nutzer und Betroffenen<br />III. Informationen zur
                  Datenverarbeitung
                </p>
                <h3>I. Informationen über uns als Verantwortliche</h3>
                <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
                Anja De Felice<br />Kolpingstr. 13<br />73240 Wendlingen<br />Deutschland
                <p>
                  E-Mail:
                  <a href="info@lamalaser.de">info@lamalaser.de</a>
                </p>

                <h3>II. Rechte der Nutzer und Betroffenen</h3>

                <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>

                <ul>
                  <li>
                    auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere
                    Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);
                  </li>

                  <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>

                  <li>
                    auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere
                    Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18
                    DSGVO;
                  </li>

                  <li>
                    auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere
                    Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
                  </li>

                  <li>
                    auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den
                    Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).
                  </li>
                </ul>

                <p>
                  Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt
                  worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der
                  Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
                  Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht
                  auf Auskunft über diese Empfänger.
                </p>

                <p>
                  <strong
                    >Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung
                    der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet
                    werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong
                  >
                </p>

                <h3>III. Informationen zur Datenverarbeitung</h3>

                <p>
                  Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der
                  Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend
                  keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
                </p>
                <h4>Cookies</h4>
                <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
                <p>
                  Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien,
                  die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese
                  Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder
                  Standortdaten oder Ihre IP-Adresse, verarbeitet.
                </p>
                <p>
                  Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung
                  bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion
                  ermöglicht.
                </p>
                <p>
                  Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder
                  Vertragsabwicklung verarbeitet werden.
                </p>
                <p>
                  Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in
                  der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
                </p>
                <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
                <h5>b) Drittanbieter-Cookies</h5>
                <p>
                  Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der
                  Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.
                </p>
                <p>
                  Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher
                  Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.
                </p>
                <h5>c) Beseitigungsmöglichkeit</h5>
                <p>
                  Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken.
                  Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen
                  hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder
                  Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die
                  Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die
                  Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret
                  genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres
                  Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.
                </p>
                <p>
                  Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht
                  sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.
                </p>
                <h4>Kontaktanfragen / Kontaktmöglichkeit</h4>
                <p>
                  Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur
                  Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich –
                  ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.
                </p>
                <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
                <p>
                  Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen
                  Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.
                </p>
                <h4>Serverdaten</h4>
                <p>
                  Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch
                  Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a.
                  Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt
                  gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des
                  jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts
                  erfolgt, erhoben.
                </p>
                <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
                <p>
                  Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in
                  der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.
                </p>
                <p>
                  Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken
                  erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der
                  Löschung ausgenommen.
                </p>
                <h4>Vertragsabwicklung</h4>
                <p>
                  Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder Dienstleistungsangebots übermittelten Daten werden von uns zum
                  Zwecke der Vertragsabwicklung verarbeitet und sind insoweit erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne
                  Bereitstellung Ihrer Daten nicht möglich.
                </p>
                <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
                <p>
                  Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und handelsrechtlichen
                  Aufbewahrungsfristen beachten.
                </p>
                <p>
                  Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das mit der Warenlieferung beauftragte Transportunternehmen oder
                  an den Finanzdienstleister weiter, soweit die Weitergabe zur Warenauslieferung oder zu Bezahlzwecken erforderlich ist.
                </p>
                <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>
                <h4>Instagram</h4>
                <p>
                  Zur Bewerbung unserer Produkte und Leistungen sowie zur Kommunikation mit Interessenten oder Kunden betreiben wir eine
                  Firmenpräsenz auf der Plattform Instagram.
                </p>
                <p>
                  Auf dieser Social-Media-Plattform sind wir gemeinsam mit der Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin
                  2, Irland, verantwortlich.
                </p>
                <p>Der Datenschutzbeauftragte von Instagram kann über ein Kontaktformular erreicht werden:</p>
                <p>
                  <a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noopener nofollow"
                    >https://www.facebook.com/help/contact/540977946302970</a
                  >
                </p>
                <p>
                  Die gemeinsame Verantwortlichkeit haben wir in einer Vereinbarung bezüglich der jeweiligen Verpflichtungen im Sinne der
                  DSGVO geregelt. Diese Vereinbarung, aus der sich die gegenseitigen Verpflichtungen ergeben, ist unter dem folgenden Link
                  abrufbar:
                </p>
                <p>
                  <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noopener nofollow"
                    >https://www.facebook.com/legal/terms/page_controller_addendum</a
                  >
                </p>
                <p>
                  Rechtsgrundlage für die dadurch erfolgende und nachfolgend wiedergegebene Verarbeitung von personenbezogenen Daten ist
                  Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht an der Analyse, der Kommunikation sowie dem Absatz und
                  der Bewerbung unserer Produkte und Leistungen.
                </p>
                <p>
                  Rechtsgrundlage kann auch eine Einwilligung des Nutzers gemäß Art. 6 Abs. 1 lit. a DSGVO gegenüber dem Plattformbetreiber
                  sein. Die Einwilligung hierzu kann der Nutzer nach Art. 7 Abs. 3 DSGVO jederzeit durch eine Mitteilung an den
                  Plattformbetreiber für die Zukunft widerrufen.
                </p>
                <p>
                  Bei dem Aufruf unseres Onlineauftritts auf der Plattform Instagram werden von der Facebook Ireland Ltd. als Betreiberin
                  der Plattform in der EU Daten des Nutzers (z.B. persönliche Informationen, IP-Adresse etc.) verarbeitet.
                </p>
                <p>
                  Diese Daten des Nutzers dienen zu statistischen Informationen über die Inanspruchnahme unserer Firmenpräsenz auf
                  Instagram. Die Facebook Ireland Ltd. nutzt diese Daten zu Marktforschungs- und Werbezwecken sowie zur Erstellung von
                  Profilen der Nutzer. Anhand dieser Profile ist es der Facebook Ireland Ltd. beispielsweise möglich, die Nutzer innerhalb
                  und außerhalb von Instagram interessenbezogen zu bewerben. Ist der Nutzer zum Zeitpunkt des Aufrufes in seinem Account auf
                  Instagram eingeloggt, kann die Facebook Ireland Ltd. zudem die Daten mit dem jeweiligen Nutzerkonto verknüpfen.
                </p>
                <p>
                  Im Falle einer Kontaktaufnahme des Nutzers über Instagram werden die bei dieser Gelegenheit eingegebenen personenbezogenen
                  Daten des Nutzers zur Bearbeitung der Anfrage genutzt. Die Daten des Nutzers werden bei uns gelöscht, sofern die Anfrage
                  des Nutzers abschließend beantwortet wurde und keine gesetzlichen Aufbewahrungspflichten, wie z.B. bei einer
                  anschließenden Vertragsabwicklung, entgegenstehen.
                </p>
                <p>Zur Verarbeitung der Daten werden von der Facebook Ireland Ltd. ggf. auch Cookies gesetzt.</p>
                <p>
                  Sollte der Nutzer mit dieser Verarbeitung nicht einverstanden sein, so besteht die Möglichkeit, die Installation der
                  Cookies durch eine entsprechende Einstellung des Browsers zu verhindern. Bereits gespeicherte Cookies können ebenfalls
                  jederzeit gelöscht werden. Die Einstellungen hierzu sind vom jeweiligen Browser abhängig. Bei Flash-Cookies lässt sich die
                  Verarbeitung nicht über die Einstellungen des Browsers unterbinden, sondern durch die entsprechende Einstellung des
                  Flash-Players. Sollte der Nutzer die Installation der Cookies verhindern oder einschränken, kann dies dazu führen, dass
                  nicht sämtliche Funktionen von Facebook vollumfänglich nutzbar sind.
                </p>
                <p>
                  Näheres zu den Verarbeitungstätigkeiten, deren Unterbindung und zur Löschung der von Instagram verarbeiteten Daten finden
                  sich in der Datenrichtlinie von Instagram:
                </p>
                <p>
                  <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener nofollow"
                    >https://help.instagram.com/519522125107875</a
                  >
                </p>
                <p>
                  Es ist nicht ausgeschlossen, dass die Verarbeitung durch die Facebook Ireland Ltd. auch über die Meta Platforms, Inc.,
                  1601 Willow Road, Menlo Park, California 94025 in den USA erfolgt.
                </p>
                <h4>Allgemeine Verlinkung auf Profile bei Drittanbietern</h4>
                <p>Der Anbieter setzt auf der Website eine Verlinkung auf die nachstehend aufgeführten sozialen Netzwerke ein.</p>
                <p>
                  Rechtsgrundlage ist hierbei Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse des Anbieters besteht an der
                  Verbesserung der Nutzungsqualität der Website.
                </p>
                <p>
                  Die Einbindung der Plugins erfolgt dabei über eine verlinkte Grafik. Erst durch einen Klick auf die entsprechende Grafik
                  wird der Nutzer zu dem Dienst des jeweiligen sozialen Netzwerks weitergeleitet.
                </p>
                <p>
                  Nach der Weiterleitung des Kunden werden durch das jeweilige Netzwerk Informationen über den Nutzer erfasst. Dies sind
                  zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte Seite. Ist der Nutzer währenddessen in seinem Benutzerkonto des
                  jeweiligen Netzwerks eingeloggt, kann der Netzwerk-Betreiber ggf. die gesammelten Informationen des konkreten Besuches des
                  Nutzers dem persönlichen Account des Nutzers zuordnen. Interagiert der Nutzer über einen „Teilen“-Button des jeweiligen
                  Netzwerks, können diese Informationen in dem persönlichen Benutzerkonto des Nutzers gespeichert und ggf. veröffentlicht
                  werden. Will der Nutzer verhindern, dass die gesammelten Informationen unmittelbar seinem Benutzerkonto zugeordnet werden,
                  muss sich der Nutzer vor dem Anklicken der Grafik ausloggen. Zudem besteht die Möglichkeit, das jeweilige Benutzerkonto
                  entsprechend zu konfigurieren.
                </p>
                <p>Folgende soziale Netzwerke sind vom Anbieter verlinkt:</p>
                <h4>Instagram</h4>
                <p>Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland.</p>
                <p>
                  Datenschutzerklärung:
                  <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noopener nofollow"
                    >https://help.instagram.com/519522125107875</a
                  >
                </p>
                <h4>FontAwesome</h4>
                <p>
                  Um Schriften und visuelle Elemente unseres Internetauftritts darstellen zu können, setzen wir die externen Schriftarten
                  von FontAwesome ein. Bei FontAwesome handelt es sich um einen Dienst der
                </p>
                <p>Fonticons Inc., 6 Porter Road, Apartment 3R, Cambridge, MA 02140, USA, nachfolgend nur „FontAwesome“ genannt.</p>
                <p>
                  Bei dem Aufruf unseres Internetauftritts wird eine Verbindung zum Server von FontAwesome in den USA aufgebaut, um so die
                  Darstellung von Schriften und visuellen Elemente zu ermöglichen und zu aktualisieren.
                </p>
                <p>
                  Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem
                  wirtschaftlichen Betrieb unseres Internetauftritts.
                </p>
                <p>
                  Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zum Server von FontAwesome kann FontAwesome
                  ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Darstellung der Schrift zu
                  übermitteln ist.
                </p>
                <p>FontAwesome bietet unter</p>
                <p>
                  <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener nofollow">https://fontawesome.com/privacy</a>
                </p>
                <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
                <h4>Google Fonts</h4>
                <p>
                  In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten ein. Es handelt sich hierbei um
                  einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.
                </p>
                <p>
                  Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres
                  Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.
                </p>
                <p>
                  Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem
                  wirtschaftlichen Betrieb unseres Internetauftritts.
                </p>
                <p>
                  Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zu Google kann Google ermitteln, von welcher
                  Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Darstellung der Schrift zu übermitteln ist.
                </p>
                <p>Google bietet unter</p>
                <p>
                  <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener nofollow"
                    >https://adssettings.google.com/authenticated</a
                  >
                </p>
                <p>
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener nofollow"
                    >https://policies.google.com/privacy</a
                  >
                </p>
                <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
                <h4>WhatsApp Kontaktaufnahme</h4>
                <p>
                  Zur Kontaktaufnahme ermöglicht der Anbieter dem Kunden unter anderem die Kontaktmöglichkeit über den Messenger-Dienst
                  WhatsApp. WhatsApp ist ein Dienst der WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
                  Irland, nachfolgend WhatsApp, eine Tochtergesellschaft von Facebook.
                </p>
                <p>
                  Durch die Kommunikation des Nutzers mit dem Anbieter über WhatsApp erhält sowohl der Anbieter, als auch WhatsApp die
                  Mobilrufnummer des Nutzers und die Information, dass der Nutzer den Anbieter kontaktiert hat.
                </p>
                <p>
                  Die vorgenannten Daten werden von WhatsApp auch an Server von Facebook in den USA weitergeleitet und von WhatsApp und
                  Facebook entsprechend der WhatsApp-Datenschutzrichtlinie verarbeitet, was auch die Verarbeitung zu deren eigenen Zwecken,
                  wie der Verbesserung des WhatsApp-Dienstes, beinhaltet.
                </p>
                <p>_____________________</p>
                <p>
                  <strong
                    >Die USA verfügen gegenwärtig nach Ansicht der Datenschutzaufsichtsbehörden allerdings nicht über ein angemessenes
                    Datenschutzniveau. Es bestehen allerdings sogenannte Standardvertragsklauseln:</strong
                  >
                </p>
                <p>
                  <a href="https://faq.whatsapp.com/general/about-standard-contractual-clauses" rel="nofollow noopener" target="_blank"
                    >https://faq.whatsapp.com/general/about-standard-contractual-clauses</a
                  >
                </p>
                <p>
                  <strong
                    >Jedoch sind dies privatrechtliche Vereinbarungen und haben daher keine direkte Auswirkung auf die Zugriffsmöglichkeiten
                    der Behörden in den USA.</strong
                  >
                </p>
                <p>_____________________</p>
                <p>
                  Näheres zum Zweck und Umfang der Datenerhebung und der weiteren Verarbeitung dieser Daten durch WhatsApp und Facebook
                  sowie diesbezügliche Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre sind in der Datenschutzrichtlinie
                  von WhatsApp enthalten:
                </p>
                <p>
                  <a href="https://www.whatsapp.com/legal/#privacy-policy" rel="nofollow noopener" target="_blank"
                    >https://www.whatsapp.com/legal/#privacy-policy</a
                  >.
                </p>
                <p>
                  Rechtsgrundlage dieser Verarbeitungen und der Übermittlung an WhatsApp ist Art. 6 Abs. 1 S. 1 b. DSGVO, soweit die
                  Kontaktaufnahme eine bestehende Vertragsbeziehung betrifft oder der Anbahnung einer solchen Vertragsbeziehung dient.
                  Sollte die Kontaktaufnahme nicht aufgrund der vorstehenden Zwecke erfolgen, ist Rechtsgrundlage Art. 6 Abs. 1 lit. f
                  DSGVO. Das berechtigte Interesse des Anbieters besteht an der Verbesserung der Servicequalität.
                </p>

                <h4>Einsatz von PayPal als Zahlungsart</h4>
                <p>
                  Sollten Sie sich im Rahmen Ihres Bestellvorgangs für eine Bezahlung mit dem Online-Zahlungsdienstleister PayPal
                  entscheiden, werden im Rahmen der so ausgelösten Bestellung Ihre Kontaktdaten an PayPal übermittelt. PayPal ist ein
                  Angebot der PayPal (Europe) S.à.r.l. &amp; Cie. S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg. PayPal übernimmt dabei
                  die Funktion eines Online-Zahlungsdienstleisters sowie eines Treuhänders und bietet Käuferschutzdienste an.
                </p>
                <p>
                  Bei den an PayPal übermittelten personenbezogenen Daten handelt es sich zumeist um Vorname, Nachname, Adresse,
                  Telefonnummer, IP-Adresse, E-Mail-Adresse, oder andere Daten, die zur Bestellabwicklung erforderlich sind, als auch Daten,
                  die im Zusammenhang mit der Bestellung stehen, wie Anzahl der Artikel, Artikelnummer, Rechnungsbetrag und Steuern in
                  Prozent, Rechnungsinformationen, usw.<br />
                  Diese Übermittelung ist zur Abwicklung Ihrer Bestellung mit der von Ihnen ausgewählten Zahlungsart notwendig, insbesondere
                  zur Bestätigung Ihrer Identität, zur Administration Ihrer Zahlung und der Kundenbeziehung. Die Übermittlung Ihrer Daten an
                  PayPal erfolgt daher auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO.
                </p>
                <p>
                  Bitte beachten Sie jedoch: Personenbezogenen Daten können seitens PayPal auch an Leistungserbringer, an Subunternehmer
                  oder andere verbundene Unternehmen weitergegeben werden, soweit dies zur Erfüllung der vertraglichen Verpflichtungen aus
                  Ihrer Bestellung erforderlich ist oder die personenbezogenen Daten im Auftrag verarbeitet werden sollen.
                </p>
                <p>
                  Abhängig von der über PayPal ausgewählten Zahlungsart, z.B. Rechnung oder Lastschrift, werden die an PayPal übermittelten
                  personenbezogenen Daten von PayPal an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung dient der Identitäts- und
                  Bonitätsprüfung in Bezug auf die von Ihnen getätigte Bestellung. Um welche Auskunfteien es sich hierbei handelt und welche
                  Daten von PayPal allgemein erhoben, verarbeitet, gespeichert und weitergegeben werden, entnehmen Sie der
                  Datenschutzerklärung von PayPal unter https://www.paypal.com/de/webapps/mpp/ua/privacy-full
                </p>

                <p>
                  Erstellt mit Unterstützung der
                  <a
                    href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
                    target="_blank"
                    rel="noopener"
                    >Anwaltskanzlei Weiß &amp; Partner</a
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- /.tab-content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditionArea",
  data() {
    return {
      terms_data: [
        {
          id: "opt1",
          active: true,
          title: "Impressum",
          p1: "Gesetzliche Offenlegung ",
          p2: "Informationen gemäß § 5 DDG",
          date: "Aktualisiert am: 11.09.2024",
          comp: "Anja De Felice - LAMA laser manufactury",
          add1: "Kolpingstr. 13",
          add2: "73240 Wendlingen",
          add3: "Deutschland",
          add4: "",
          add5: "",
          add6: "",
          add7: "",
          add8: "",
          add9: "Kontakt",
          add10: "info@lamalaser.de",
          add11: "https://lamalaser.de",
          para: [
            {
              title: "1. Warnhinweis zu Inhalten",
              text: "Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Die Nutzung dieser Webseiteninhalte erfolgt auf eigene Gefahr. Allein durch den Aufruf dieser kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.",
            },
            {
              title: "2. Verlinkungen ",
              text: 'Die Webseite enthält Verlinkungen zu anderen Webseiten ("externe Links"). Diese Webseiten unterliegen der Haftung der jeweiligen Seitenbetreiber. Bei Verknüpfung der externen Links waren keine Rechtsverstöße ersichtlich. Auf die aktuelle und künftige Gestaltung der verlinkten Seiten hat der Anbieter keinen Einfluss. Die permanente Überprüfung der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Bekanntwerden von Rechtsverstößen werden die betroffenen externen Links unverzüglich gelöscht.',
            },
            {
              title: "3. Urheberrecht / Leistungsschutzrecht",
              text: "Die auf dieser Webseite durch den Anbieter veröffentlichten Inhalte unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Alle vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt vor allem für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Dabei sind Inhalte und Rechte Dritter als solche gekennzeichnet. Das unerlaubte Kopieren der Webseiteninhalte oder der kompletten Webseite ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt. </br>Diese Website darf ohne schriftliche Erlaubnis nicht durch Dritte in Frames oder iFrames dargestellt werden.",
            },
            {
              title: "4. Keine Werbung",
              text: "Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei denn der Anbieter hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.",
            },
            {
              title: "5. Besondere Nutzungsbedingungen",
              text: "Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Nummern 1. bis 4. abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Bedingungen.",
            },
          ],
        },
        {
          id: "opt2",
          title: "Datenschutzerklärung",
          date: "Aktualisiert am: 11.09.2024",
        },
      ],
    };
  },
};
</script>
