<template>
  <div>
    <div class="fancy-portfolio-two lg-container">
      <div class="container">
        <div class="top-border bottom-border pb-130 md-pb-90">
          <div class="controls po-control-one text-center mb-90 md-mb-50 mt-90 md-mt-60">
            <button type="button" :class="`control ${isActive === 'all' ? 'active' : ''} `" @click="handleItem('all')">Alle</button>
            <button type="button" :class="`control ${isActive === 'holz' ? 'active' : ''} `" @click="handleItem('holz')">Holz</button>
            <button type="button" :class="`control ${isActive === 'glas' ? 'active' : ''} `" @click="handleItem('glas')">Glas</button>
            <button type="button" :class="`control ${isActive === 'stahl' ? 'active' : ''} `" @click="handleItem('stahl')">
              Edelstahl
            </button>
            <button type="button" :class="`control ${isActive === 'leder' ? 'active' : ''} `" @click="handleItem('leder')">Leder</button>
            <button type="button" :class="`control ${isActive === 'papier' ? 'active' : ''} `" @click="handleItem('papier')">Papier</button>
            <!-- <button
              type="button"
              :class="`control ${isActive === 'stein' ? 'active' : ''} `"
              @click="handleItem('stein')"
            >
              Stein
            </button> -->
          </div>

          <div class="mixitUp-container gutter-space-one d-flex flex-wrap">
            <div v-for="(item, imageIndex) in p_items" :key="item.id" :class="`mix ${item.large_size ? 'mixL' : ''}`" data-aos="fade-right">
              <div class="portfolio-block-two position-relative">
                <img :src="item.img" alt="" class="w-100 h-100 tran4s img-meta" />
                <button @click="showImg(imageIndex)" v-if="!item.video" class="fancybox d-flex align-items-center justify-content-center">
                  <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                </button>
                <button
                  v-else
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                  class="fancybox d-flex align-items-center justify-content-center"
                >
                  <i class="fa fa-play" aria-hidden="true"></i>
                </button>
                <div class="hover-content">
                  <h3 style="color: white; font-size: 1.25rem">
                    {{ item.title }}
                  </h3>
                  <div class="tag">{{ item.tag }}</div>
                </div>
                <!-- /.hover-content -->
              </div>
              <!-- /.portfolio-block-two -->
            </div>
          </div>

          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; width: 100%; margin-top: 75px">
            <div style="margin-bottom: 10px">Folge uns auch gern auf ...</div>
            <div @mouseover="hover = true" @mouseleave="hover = false" style="margin-top: 2px">
              <a class="link-item" href="https://www.instagram.com/anja_de_felice_lama_laser/" target="_blank" v-if="!hover"
                ><img style="width: 75px" src="@/assets/images/instagram.png" alt="instagram" />
              </a>
              <a class="link-item" href="https://www.instagram.com/anja_de_felice_lama_laser/" target="_blank" v-else
                ><img style="width: 75px" src="@/assets/images/instagram-custom.png" alt="instagram"
              /></a>
            </div>
          </div>
          <!-- /.mixitUp-container -->

          <!-- <div class="text-center mt-50">
            <button class="theme-btn-nine">Load More</button>
          </div> -->
        </div>
      </div>
    </div>

    <!-- image light box start -->
    <vue-easy-lightbox :visible="visible" :imgs="p_items?.slice(0, 17).map((img) => img.img)" :index="index" @hide="handleHide">
    </vue-easy-lightbox>
    <!-- image light box end -->

    <!-- VideoModal start -->
    <!-- <VideoModal videoId="aXFSJTjVjw0" /> -->
    <!-- VideoModal end -->
  </div>
</template>

<script>
import portfolioMixin from "@/mixin/portfolio-mixin";
import VueEasyLightbox from "vue-easy-lightbox";
// import VideoModal from "../common/Modal/VideoModal.vue";

export default {
  name: "FancyPortfolio",
  mixins: [portfolioMixin],
  components: {
    VueEasyLightbox,
    // VideoModal,
  },
  data() {
    return {
      hover: false,
      p_items: [],
      isActive: "all",
      visible: false,
      index: 0,
      loadMoreIndex: 0,
    };
  },
  methods: {
    handleItem(category) {
      this.isActive = category;
      if (category === "all") {
        return (this.p_items = this.portfolioItems.slice(0, 28));
      } else {
        return (this.p_items = this.portfolioItems.filter((item) => item.category === category));
      }
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },

    loadMore() {
      this.p_items = this.portfolioItems.slice(this.loadMoreIndex, this.loadMoreIndex + 3);

      this.loadMoreIndex = this.loadMoreIndex + 3;

      return this.p_items;
    },
  },
  mounted() {
    this.p_items = this.portfolioItems.slice(0, 28);
  },
};
</script>
