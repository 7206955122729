<template>
  <div id="#" class="hero-banner-one">
    <div class="hero-upper-container background-wa" style="padding: 7rem 0">
      <!-- <div style="background-color: #dfdfdf" class="icon-box-one">
        <img src="../../assets/images/logo/01.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-two">
        <img src="../../assets/images/logo/02.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-three">
        <img src="../../assets/images/logo/03.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-four">
        <img src="../../assets/images/logo/04.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-five">
        <img src="../../assets/images/logo/05.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-six">
        <img src="../../assets/images/logo/06.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-seven">
        <img src="../../assets/images/logo/07.png" alt="" />
      </div>
      <div style="background-color: #dfdfdf" class="icon-box-eight">
        <img src="../../assets/images/logo/08.png" alt="" />
      </div> -->

      <div class="bubble-one"></div>
      <div class="bubble-two"></div>
      <div class="bubble-three"></div>
      <div class="bubble-four"></div>
      <div class="bubble-five"></div>
      <div class="bubble-six"></div>
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 m-auto">
            <h1 class="wa-font-3 hero-heading" style="color: #73737b; font-weight: 100; font-size: 6.5rem; line-height: 4rem">
              LAMA <br />
              <span class="wa-span" style="font-size: 3.5rem">laser manufactory</span>
            </h1>
            <br /><br />
            <p class="" style="font-size: 1.5rem">digital wird real | individuelle laser-unikate</p>
          </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: center">
          <a class="theme-btn-one mt-50 md-mt-30" style="text-transform: uppercase" href="#portfolio"> Portfolio </a>
        </div>
      </div>
    </div>
    <!-- /.hero-upper-container -->
  </div>
</template>

<script>
export default {
  name: "HeroArea",
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },
};
</script>

<style>
.background-wa {
  background-image: url("./../../assets/images/img_background_1f.jpeg");
  background-position: center;
  background-size: cover;
}
</style>
