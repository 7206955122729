export default {
  data() {
    return {
      portfolioItems: [
        //  v1
        {
          id: 1,
          img: require(`@/assets/images/img_lama/img_prod_g_2.jpg`),
          title: "Sektgläser",
          tag: "Gravur, Glas",
          category: "glas",
        },
        {
          id: 2,
          img: require(`@/assets/images/img_lama/img_prod_g_3.jpg`),
          title: "Firmenbleistifte",
          tag: "Gravur, Holz",
          category: "holz",
        },
        {
          id: 3,
          img: require(`@/assets/images/img_lama/img_prod_g_5.jpg`),
          title: "Thermobecher",
          tag: "Gravur, Edelstahl",
          category: "stahl",
        },
        {
          id: 4,
          img: require(`@/assets/images/img_lama/img_prod_w_9.jpg`),
          title: "Ohrringe",
          tag: "Schnitt, Holz",
          category: "holz",
        },
        {
          id: 5,
          img: require(`@/assets/images/img_lama/img_prod_g_4.jpg`),
          title: "Allzweckmesser",
          tag: "Gravur, Edelstahl",
          large_size: true,
          category: "stahl",
        },
        {
          id: 6,
          img: require(`@/assets/images/img_lama/img_prod_g_1.jpg`),
          title: "Pizzabretter",
          tag: "Gravur, Holz",
          large_size: true,
          category: "holz",
        },
        {
          id: 7,
          img: require(`@/assets/images/img_lama/img_prod_g_7.jpg`),
          title: "Weingläser",
          tag: "Gravur, Glas",
          category: "glas",
        },
        {
          id: 8,
          img: require(`@/assets/images/img_lama/img_prod_w_7.jpg`),
          title: "Lesezeichen",
          tag: "Schnitt, Kraftpapier",
          category: "papier",
        },
        {
          id: 9,
          img: require(`@/assets/images/img_lama/img_prod_g_6.jpg`),
          title: "Gürtel",
          tag: "Gravur, Leder",
          large_size: true,
          category: "leder",
        },
        {
          id: 10,
          img: require(`@/assets/images/img_lama/img_prod_w_1.jpg`),
          title: "Geburtswolken",
          tag: "Gravur, Schnitt, Holz",
          large_size: true,
          category: "holz",
        },
        {
          id: 11,
          img: require(`@/assets/images/img_lama/img_prod_g_8.jpg`),
          title: "Weingläser",
          tag: "Gravur, Glas",
          category: "glas",
        },
        {
          id: 12,
          img: require(`@/assets/images/img_lama/img_prod_w_6.jpg`),
          title: "Windlichter",
          tag: "Schnitt, Holz",
          category: "holz",
        },
        {
          id: 13,
          img: require(`@/assets/images/img_lama/img_prod_w_3.jpg`),
          title: "Firmenschilder",
          tag: "Schnitt, Holz",
          large_size: true,
          category: "holz",
        },
        {
          id: 14,
          img: require(`@/assets/images/img_lama/img_prod_w_4.jpg`),
          title: "Windlichter",
          tag: "Schnitt, Holz",
          large_size: true,
          category: "holz",
        },
        {
          id: 15,
          img: require(`@/assets/images/img_lama/img_prod_w_8.jpg`),
          title: "Namensschilder",
          tag: "Schnitt, Holz",
          category: "holz",
        },
        {
          id: 16,
          img: require(`@/assets/images/img_lama/img_prod_w_5.jpg`),
          title: "Küchenbretter",
          tag: "Gravur, Holz",
          large_size: true,
          category: "holz",
        },
        {
          id: 17,
          img: require(`@/assets/images/img_lama/img_prod_w_2.jpg`),
          title: "Kugelschrei - 🐻",
          tag: "Gravur, Holz",
          category: "holz",
        },
        {
          id: 18,
          img: require(`@/assets/images/img_lama/img_birthday_wood.jpg`),
          title: "Geburtstagsplatten",
          tag: "Schnitt, Holz",
          category: "holz",
        },
        {
          id: 19,
          img: require(`@/assets/images/img_lama/img_box_wood.jpg`),
          title: 'Geschenkbox "Hochzeit"',
          tag: "Gravur, Holz",
          large_size: true,
          category: "holz",
        },
        {
          id: 20,
          img: require(`@/assets/images/img_lama/img_knife_steel.jpg`),
          title: "Küchenmesser",
          tag: "Gravur, Edelstahl",
          large_size: true,
          category: "stahl",
        },
        {
          id: 21,
          img: require(`@/assets/images/img_lama/img_bracelet_leather.jpg`),
          title: "Armbänder",
          tag: "Gravur, Leder",
          category: "leder",
        },
        {
          id: 22,
          img: require(`@/assets/images/img_lama/img_nameplate_wood.jpg`),
          title: 'Platzkartenanhänger "Hochzeit"',
          tag: "Schnitt, Holz",
          category: "holz",
        },
        {
          id: 23,
          img: require(`@/assets/images/img_lama/img_thermocup_steel.jpg`),
          title: "Thermobecher",
          tag: "Gravur, Edelstahl",
          category: "stahl",
        },
        {
          id: 24,
          img: require(`@/assets/images/img_lama/img_tumbler_glass.jpg`),
          title: "Tumbler",
          tag: "Gravur, Glas",
          category: "glas",
        },
        {
          id: 25,
          img: require(`@/assets/images/img_lama/img_shotglass_steel.jpg`),
          title: 'Schnapsbecher "Party"',
          tag: "Gravur, Edelstahl",
          category: "stahl",
          large_size: true,
        },
        {
          id: 26,
          img: require(`@/assets/images/img_lama/img_winelabel_wood.jpg`),
          title: 'Etiketten "Weinflaschen"',
          tag: "Gravur, Holz",
          category: "holz",
        },
        //  v-2
        // {
        //   id: 9,
        //   img: require(`@/assets/images/gallery/img_09.jpg`),
        //   title: "The Gang Bllue",
        //   tag: "Development, Design",
        //   category: "dev-2",
        // },
        // {
        //   id: 10,
        //   img: require(`@/assets/images/gallery/img_10.jpg`),
        //   title: "Core WordPress",
        //   tag: "Development, Plugin",
        //   video: true,
        //   category: "plugin-2",
        // },
        // {
        //   id: 11,
        //   img: require(`@/assets/images/gallery/img_11.jpg`),
        //   title: "Platform Audit",
        //   tag: "Development, Branding",
        //   category: "design-2",
        // },
        // {
        //   id: 12,
        //   img: require(`@/assets/images/gallery/img_12.jpg`),
        //   title: "Medieval origins",
        //   tag: "Development, Design",
        //   video: true,
        //   category: "branding-2",
        // },
        // {
        //   id: 13,
        //   img: require(`@/assets/images/gallery/img_13.jpg`),
        //   title: "Designer’s Checklist",
        //   tag: "Development, Plugin",
        //   category: "dev-2",
        // },
        // {
        //   id: 14,
        //   img: require(`@/assets/images/gallery/img_14.jpg`),
        //   title: "Motivation defining Moment",
        //   tag: "Development, Design",
        //   category: "design-2",
        // },
        // // v-3

        // {
        //   id: 15,
        //   img: require(`@/assets/images/gallery/img_15.jpg`),
        //   title: "The Gang Bdlue",
        //   tag: "Development, Design",
        //   category: "dev-3",
        // },
        // {
        //   id: 16,
        //   img: require(`@/assets/images/gallery/img_16.jpg`),
        //   title: "Core WordPress",
        //   tag: "Development, Plugin",
        //   video: true,
        //   category: "plugin-3",
        // },
        // {
        //   id: 17,
        //   img: require(`@/assets/images/gallery/img_17.jpg`),
        //   title: "Platform Audit",
        //   tag: "Development, Branding",
        //   category: "design-3",
        // },
        // {
        //   id: 18,
        //   img: require(`@/assets/images/gallery/img_18.jpg`),
        //   title: "Medieval origins",
        //   tag: "Development, Design",
        //   video: true,
        //   category: "branding-3",
        // },
        // {
        //   id: 19,
        //   img: require(`@/assets/images/gallery/img_19.jpg`),
        //   title: "Designer’s Checklist",
        //   tag: "Development, Plugin",
        //   category: "dev-3",
        // },
        // {
        //   id: 20,
        //   img: require(`@/assets/images/gallery/img_20.jpg`),
        //   title: "Motivation defining Moment",
        //   tag: "Development, Design",
        //   category: "plugin-3",
        // },
        // {
        //   id: 21,
        //   img: require(`@/assets/images/gallery/img_21.jpg`),
        //   title: "Our experience WordCamp",
        //   tag: "Development, Branding",
        //   video: true,
        //   category: "design-3",
        // },
        // {
        //   id: 22,
        //   img: require(`@/assets/images/gallery/img_22.jpg`),
        //   title: "Lets catch up amd",
        //   tag: "Development, Design",
        //   category: "plugin-3",
        // },
        // {
        //   id: 23,
        //   img: require(`@/assets/images/gallery/img_23.jpg`),
        //   title: "Experience WordCamp",
        //   tag: "Development, Branding",
        //   category: "dev-3",
        // },

        // portfolio slider
        // {
        //   id: 24,
        //   img: require(`@/assets/images/gallery/img_24.jpg`),
        //   title: "User Interface",
        //   subtitle: "Deski - Art & Illustrationp",
        // },
        // {
        //   id: 25,
        //   img: require(`@/assets/images/gallery/img_25.jpg`),
        //   title: "Web developemnt",
        //   subtitle: "Deski - Art & Illustrationp",
        // },
        // {
        //   id: 26,
        //   img: require(`@/assets/images/gallery/img_26.jpg`),
        //   title: "Social Marketing",
        //   subtitle: "Deski - Art & Illustrationp",
        // },
        // {
        //   id: 27,
        //   img: require(`@/assets/images/gallery/img_25.jpg`),
        //   title: "lead generation",
        //   subtitle: "Deski - Art & Illustrationp",
        // },

        // portfolio v5
        // {
        //   id: 28,
        //   img: require(`@/assets/images/gallery/img_28.jpg`),
        //   title: "The Gansg Blue",
        //   tag: "Development, Design",
        //   category: "creative-4",
        // },
        // {
        //   id: 29,
        //   img: require(`@/assets/images/gallery/img_29.jpg`),
        //   title: "Core WordPress",
        //   tag: "Development, Plugin",
        //   video: true,
        //   category: "plugin-4",
        // },
        // {
        //   id: 30,
        //   img: require(`@/assets/images/gallery/img_30.jpg`),
        //   title: "Platform Audit",
        //   tag: "Development, Branding",
        //   category: "design-4",
        // },
        // {
        //   id: 31,
        //   img: require(`@/assets/images/gallery/img_31.jpg`),
        //   title: "Medieval origins",
        //   tag: "Development, Design",
        //   video: true,
        //   category: "branding-4",
        // },
        // {
        //   id: 32,
        //   img: require(`@/assets/images/gallery/img_32.jpg`),
        //   title: "Designer’s Checklist",
        //   tag: "Development, Plugin",
        //   large_size: true,
        //   category: "dev-4",
        // },
      ],
    };
  },
};
