<template>
  <footer class="theme-footer-one">
    <div class="container">
      <div class="bottom-footer-content">
        <div class="d-sm-flex align-items-center justify-content-between">
          <ul style="padding-right: 4px !important" class="order-sm-last">
            <li>
              <router-link to="/terms-condition">Impressum & Haftungsausschluss</router-link>
            </li>
            <li>
              <router-link to="/terms-condition">Datenschutzerklärung</router-link>
            </li>
          </ul>
          <p class="order-sm-first">©{{ new Date().getFullYear() }} LAMA laser manufactory | Alle Rechte vorbehalten</p>
        </div>
      </div>
      <!-- /.bottom-footer -->
    </div>
  </footer>
  <!-- /.theme-footer-one -->
</template>

<script>
export default {
  name: "FooterArea",
};
</script>
