<template>
  <div class="main-page-wrapper">
    <Header />
    <HeroArea id="home" />
    <FancyTextBlock />
    <FancyHero />
    <FancyPortfolio id="portfolio" />
    <!-- <FeatureOne />
    <FeatureTwo /> -->
    <!-- <UseableTools id="portfolio" />
    <FeatureThree />
    <ShortBanner /> -->
    <!-- <Faq /> -->
    <div
      id="contact-us"
      class="fancy-hero-six"
      style="background-color: #b0cf00; padding: 95px 0 95px"
    >
      <div class="container">
        <h3
          style="color: white; font-size: 3.75rem; font-weight: 100"
          class="wa-font-3"
        >
          Schreib uns eine Nachricht
        </h3>
      </div>
    </div>
    <ContactStyle />
    <Footer />
  </div>
</template>

<script>
import ContactStyle from "./contact-style.vue";
import FancyTextBlock from "./fancy-text-block.vue";
import FancyHero from "./Fancy-hero.vue";
import FancyPortfolio from "./Fancy-portfolio.vue";
import Header from "../common/Headers/Header.vue";
import HeroArea from "./HeroArea.vue";
// import UseableTools from "./UseableTools.vue";
// import FeatureThree from "./FeatureThree.vue";
// import ShortBanner from "./ShortBanner.vue";
// import Faq from "./Faq.vue";
import Footer from "./Footer.vue";

export default {
  name: "HomeMain",
  components: {
    FancyTextBlock,
    FancyHero,
    FancyPortfolio,
    Header,
    HeroArea,
    ContactStyle,
    // UseableTools,
    // FeatureThree,
    // ShortBanner,
    // Faq,
    Footer,
  },
};
</script>
