import { createRouter, createWebHistory } from "vue-router";
import HomePageOne from "../pages/home";
import TermsCondition from "../pages/terms-condition";

const routes = [
  {
    path: "/",
    name: "HomePageOne",
    component: HomePageOne,
    meta: {
      title: "LAMA laser manufactory",
    },
  },
  {
    path: "/terms-condition",
    name: "TermsCondition",
    component: TermsCondition,
    meta: {
      title: "Terms & Conditions | LAMA laser maufactory",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);
});

export default router;
